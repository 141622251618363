export const sidebarLinks = [
    {title: "over the counter", link: "/over-the-counter-drugs"},
    {title: "prescription medication", link: "/prescription-medication"},
    {title: "vitamins & supplements", link: "/vitamins"},
    {title: "personal care and hygiene", link: "/personal-care&hygiene"},
    {title: "medical tools", link: "/medical-tools"},
    {title: "special medication", link: "/specal-medication"},
    {title: "demertological", link: "/dermatological-products"},
    {title: "respiratory medications", link: "respiratory-medications"},

]